
import { defineComponent } from "vue";
import Template4 from "@/views/templates/Template4.vue";

export default defineComponent({
  name: "Test",
  components: {
    Template4,
  },
  data() {
    return {
      current: {
        coin: 9600,
        description: "全程1對1教學，高效學習方案。",
        evaluate: [
          {
            Uphoto: "",
            Utitle: "吳敬軒",
            comment: "老師很有耐心 講解也很細",
            rating: 5,
          },
          {
            Uphoto: "",
            Utitle: "郭育秀",
            comment:
              "發音清晰，近乎全英文的教學環境很喜歡！不會的phrase或單字，會利用definition進行講解，也會請學生試著造句跟開口~(*๓´╰╯`๓)♡",
            rating: 5,
          },
        ],
        expirationDate: null,
        id: "ONE-TI-480",
        level: null,
        like: false,
        picture: null,
        shoppingCart: false,
        sub_title: "",
        title: "托福雅思 1對1 - 480分鐘",
        type: 1,
      },
      newInfo: {
        type: 3,
        title: "基礎時態英文 (一)",
        sub_title: "",
        coin: 1040, // 套裝價格
        coinUnit: 140, // 單元價格(New)
        description:
          "使役動詞到底是什麼? 特殊用法你搞清楚了嗎? 「使役動詞」是指英文中的一類動詞，是「使」、「讓」某特定人去做某特定事，或是「使」、「讓」某件特定事發生，主要是let、make、have、get這四個字。句型、例句一次告訴你!",
        expirationDate: null,
        id: "COOR-EN-00001",
        level: 3,
        like: false, // 套裝課程
        picture: null,
        shoppingCart: false, // 套裝課程
      },
      upload: {},
    };
  },
});
